<!-- Компонент выбора категории еды --->
<template>
	<div
		ref="foodTypeSelector"
		class="food-type-selector"
		:class="{ sticky}"
	>
		<div
			class="food-type-selector__container"
			:class="{ sticky }"
		>
			<div
				class="food-type-selector__item"
				v-for="item in items"
				:key="item.type"
				@click="$emit('select', item.type)"
				:class="{sticky}"
				:style="{backgroundColor: getBgColor(item.type)}"
			>
				<div class="food-type-selector__count">
					{{ item.foodItems.length }}
				</div>
				<img
					class="food-type-selector__icon"
					:src="
						require(`@/assets/img/Personal/Menu/FoodTypes/${item.type}.svg`)
					"
				/>
				<div class="food-type-selector__name">
					{{ item.type == "lunches" ? "Ланчи" : item.title }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {dragscroll} from 'vue-dragscroll';

export default {
	name: 'FoodTypeSelector',
	props:
		{
			items:
				{
					type: Array,
					default: () => [],
				},
			sticky:
				{
					type: Boolean,
					default: false,
				},
		},
	methods: {
		getItemsCount(type)
		{

			let count = 0;

			if (type == 'lunches')
			{
				this.$store.state.cartLanches?.forEach(item =>
				{
					count += parseInt(item.count);
				})
			} else
			{
				this.$store.state.cartMeals?.forEach(item =>
				{
					if (item.product.type.code == type)
						count += parseInt(item.count);
				})
			}

			return count;
		},
		getBgColor(category)
		{
			let color = '';
			let menuCategories = this.$store.state.menuCategories.filter(c => c.type == category);

			this.items.forEach(item =>
			{
				menuCategories.forEach(r =>
				{
					if (item.type == r.type)
						color = r?.color;
				})
			})
			return `#${color}`;
		},
	},
	computed:
		{},

	directives: {
		dragscroll
	},
}
</script>

<style lang="scss">
.food-type-selector
{

	display: flex;
	flex-shrink: 0;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
	scrollbar-width: none;

	&__container
	{
		display: flex;
		flex-shrink: 0;
	}

	&__item
	{
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		cursor: pointer;
		padding-top: 16px;
		width: 88px;
		height: 88px;
		min-width: 88px;
		background: #fff;
		border: 1px solid $greyBorder;
		border-radius: 12px;
		box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.08);
		margin-right: 12px;
	}

	&__item_selected
	{
		.food-type-selector__icon
		{
			display: none;

			&_done
			{
				display: block;
			}
		}
	}

	&__item:hover
	{

		color: $green;
		transition: .2s ease color;

		.food-type-selector__icon
		{
			transform: translateY(-4px);
		}

	}

	&__count
	{
		position: absolute;
		right: 8px;
		top: 8px;
		font-size: 9px;
		line-height: 14px;
		color: $greyLightText;
		background: $greyBackground;
		padding: 0 3px;
		border: 1px solid $greyBorder;
		border-radius: 14px;
	}

	&__icon
	{
		width: 40px;
		height: 40px;
		transition: .2s ease transform;
	}

	&__name
	{
		font-size: 13px;
		font-family: $mainFontBoldNew;
	}

}

.sticky.food-type-selector
{

	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 3;
	flex-shrink: 0;
	padding: 0 32px;
	overflow-y: overlay;
	overflow-x: auto;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 72px;
	background: #fff;
	border-bottom: 1px $greyBorder solid;

	&__container
	{
		display: flex;
		width: 100%;
		max-width: 1320px;
	}

	&__item
	{
		display: flex;
		flex-direction: row;
		align-items: center;
		position: relative;
		cursor: pointer;
		width: auto;
		height: 40px;
		min-width: 88px;
		background: $greyBorder;
		border: none;
		padding: 4px 12px;
		min-width: unset;
		border-radius: 40px;
		box-shadow: none;
		margin-right: 12px;

		.food-type-selector__count
		{
			display: none;
		}

		.food-type-selector__icon
		{
			width: 32px;
			height: 32px;
			margin-right: 4px;

			&_selected
			{
				background: $green;
				color: #ffff;

				svg
				{fill: white; }

				.lunch-menu__icon
				{
					display: none;

					&_done
					{display: flex; }
				}

				&:after
				{border-left-color: $green; }
			}
		}
	}

	&__icon
	{
		width: 40px;
		height: 40px;
	}

	&__name
	{
		font-size: 13px;
		font-family: $mainFontBoldNew;
	}

}

</style>
